import type { IPassportData } from '@shveitsar/passports-types'
import type { UUID } from '@shveitsar/toolbox'
import type {
  getPhotosURLResponse,
  uploadPhotosParams
} from './types'

export * from './types'

export class PassportsClient {
  constructor(
    private endpoint: string,
    private params: {
      fetch: typeof globalThis.fetch
      FormData: typeof globalThis.FormData
    } = {
      fetch: globalThis.fetch.bind(globalThis),
      FormData: globalThis.FormData
    }
  ) {}

  async uploadPhotos({
    uuid,
    recognize,
    files
  }: uploadPhotosParams): Promise<{ status: 'ok' }> {
    const formdata = new this.params.FormData()
    for (const { type, filename, buffer } of files) {
      formdata.append(type, buffer as any, filename)
    }
    const url = new URL(this.endpoint)
    url.pathname = '/uploadphotos'
    url.searchParams.set('uuid', uuid)
    if (typeof recognize === 'boolean') {
      url.searchParams.set('recognize', recognize.toString())
    }
    const response = await this.params.fetch(url.href, {
      method: 'POST',
      body: formdata
    })
    return (await response.json()) as any
  }

  getPhotosURL(uuid: UUID): Promise<getPhotosURLResponse> {
    return this.getRequest('/photosurl', { uuid })
  }

  checkPhotos(uuid: UUID): Promise<getPhotosURLResponse> {
    return this.getRequest('/photoscheck', { uuid })
  }

  getPassportData(uuid: UUID): Promise<IPassportData> {
    return this.getRequest('/passportdata', { uuid })
  }

  private async getRequest(
    pathname: string,
    params?: Record<string, string>
  ) {
    const url = new URL(this.endpoint)
    url.pathname = pathname
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        url.searchParams.set(key, value)
      }
    }
    const response = await this.params.fetch(url.href)
    return (await response.json()) as any
  }
}
